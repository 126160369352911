import React, { useEffect, useState } from 'react'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { useStaticQuery, graphql } from "gatsby"
import {PageHeaderWrap} from './page-header.style';

const PageHeader = ({pageContext, location, title, backgroundBg}) => {
    const { breadcrumb: { crumbs } } = pageContext;
    const disableLinks = [
        "/elements", 
        "/category", 
        "/profile", 
        "/date", 
        "/tag", 
        "/page", 
        "/blog", 
        "/blog/page", 
        "/blogs",
        "/services"
    ]
    let images = [];
    const customCrumbLabel = location.pathname.toLowerCase();
    const crumbLabelArr = customCrumbLabel.split('/');
    const label = crumbLabelArr[crumbLabelArr.length - 1]
    const labelArr = label.split('-');

  const bannerImages = useStaticQuery(graphql`
    query PageHeaderQuery {        
        images : allAirtable(
                filter: {
                  table: { eq: "SiteDetails" }
                  data: { Id: { eq: "homeBanner" } }
                }
              ) {
                edges {
                  node {
                    data {
                        BannerImages{
                          url
                        }
                    }
                  }
                }
           }    
      } 
    `);

    images = bannerImages.images.edges[0].node.data.BannerImages;
    // if(images.length > 1){
      return (
        images.length > 0 && (
          <PageHeaderWrap fluid={backgroundBg ? backgroundBg : {src: images[Math.floor(Math.random() * images.length-1) + 1].url}}>
            <Breadcrumb
            title={title}
            crumbs={crumbs}
            crumbLabel={labelArr.join(' ')}            
            disableLinks={disableLinks}
            />
          </PageHeaderWrap>
        )
      )     
    // }    
}

export default PageHeader
