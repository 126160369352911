import React from "react";
import Img from "gatsby-image";

const NonStretchedImage = props => {
    let normalizedProps = props
    let alignment;
    if(props.align === 'right'){
        alignment = '0 0 0 auto'
    } else if(props.align === 'left'){
        alignment = '0 auto 0 0'
    }else{
        alignment = '0 auto'
    }
    if(normalizedProps.fluid){
        normalizedProps = {...normalizedProps.fluid, aspectRatio: 1}    
    }
    if(normalizedProps.fixed){
        normalizedProps = {...normalizedProps.fixed, aspectRatio: 1}    
    }
    if (props.fluid && props.fluid.presentationWidth) {
        normalizedProps = {
            ...props,
            style: {
                ...(props.style || {}),
                position: props.isAbsolute ? 'absolute' : 'relative',
                width: props.isAbsolute ? props.fluid.presentationWidth : '',
                maxWidth: props.fluid.presentationWidth,
                margin: alignment, 
            },
        }
    }   
    if(props.fixed){
        return <Img {...normalizedProps} fixed={props.fixed}  />    
    }
    else{
        return <Img {...normalizedProps}  />
    }
    
}

export default NonStretchedImage;